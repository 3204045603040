.btn-active:focus{
  outline: none;
  box-shadow: none;
}

.btn-active:focus-visible{
  outline: 0px solid black;
}

.active-nav{
  background-color: rgba(225, 225, 255, 0.5);
  border-radius: 5px;
  font-weight: 500;

}

.nav-item:hover{
  background-color: rgba(225, 225, 255, 0.5);
  border-radius: 5px;
}

.nav-link.active {
  background-color: #007bff;
  color: #fff;
}